/* -------------- news RWD --------------*/
@media (max-width: $w_pc_b){ 
    .page-news-list{
        gap: 30px;
    }
}

@media (max-width: $w_pc){ 
    .page-news-list{
        gap: 30px 25px;
        .news{
            padding: 10px;
        }
    }
}

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-news-list{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: $w_tb_m){ 
    .page-news-list{
        grid-template-columns: 1fr;
    }
}

// @media (max-width: $w_mo){ }