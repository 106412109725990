/* -------------- form --------------*/
button, input[type="reset"], input[type="submit"]{
	cursor: pointer;
}

input[type="reset"], input[type="submit"], input[type="text"], input[type="email"], input[type="tel"], textarea, select, input[type="date"], input[type="time"], input[type="number"], input[type="password"], input[type="url"], button{
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
	box-shadow: none;
	border: none;
	outline: none;
}

select::-ms-expand{
	display: none;
}

.select-style{
	background: $c_white;
    position: relative;
    &:before{
        @include abs_pos($t: 50%, $r: 10px);
        content: '\f0dd';
        display: block;
        font-family: 'Font Awesome 6 Free';
        font-weight: bold;
        color: $c_font;
        transform: translateY(-80%);
    }
    select{
        background: transparent;
        padding: 10px;
        padding-right: 25px;
        position: relative;
        width: 100%;
        z-index: 1;
    }
}
