$c_font: #333;
$c_fontL: #777;
$c_white: #fff;
$c_main: #961d1c;
$c_dark: #4a0d0a;
$c_light: #a6937c;
$c_active: #ffbf00;
$c_grey: #e2e2e2;

$w_pc_b: calc(1440px + 40px);
$w_pc: 1280px;
$w_pc_m: 1024px;
$w_tb_b: 960px;
$w_tb: 768px;
$w_tb_m: 640px;
$w_mo: 480px;
$w_i6p: 414px;
$w_i6: 375px;
$w_mo_m: 320px;

$fz: 10px;

$fa-font-path: "../webfonts";