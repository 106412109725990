/* -------------- index --------------*/
.index-banner{
    @include flex;
    background: $c_main;
    padding: 0 20px;
    img{
        display: block;
        width: 100%;
    }
    .person-slider{
        width: 28.2%;
    }
    .temple-slider{
        width: 71.8%;
    }
}

.index-head{
    align-items: baseline;
    display: flex;
    margin-bottom: 20px;
    p{
        margin: 0;
    }
    .main{
        color: $c_dark;
        font-size: 1.625rem /* 26/16 */;
        font-weight: bold;
        line-height: 1.2;
        position: relative;
        &:after{
            color: #444;
            content: '/';
            font-weight: lighter;
            padding: 0 20px;
        }
    }
    .sub{
        color: $c_font;
        font-size: 1.625rem /* 26/16 */;
    }
}

.index-main{
    background: url(../images/index-flower.png) no-repeat;
    background-position: left bottom;
    padding-top: 80px;
    padding-bottom: 100px;
    p{
        margin: 0;
    }
    .frame{
        @include flex($ai:flex-start);
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .right-sec{
        width: 63%;
    }
    .index-news{
        margin-bottom: 55px;
    }
    .news{
        margin-bottom: 22px;
        padding-left: 20px;
        position: relative;
        &:before{
            @include abs_pos($t:9px, $l:0);
            @include size(7px, 2px);
            background: #ababab;
            content: '';
        }
        .top-row{
            align-items: center;
            display: flex;
            gap: 10px;
            .date{
                color: $c_active;
                font-size: 1.125rem /* 18/16 */;
                flex-shrink: 0;
                line-height: 1.2;
            }
            .news-title {
                @include lineLimit(1);
                a{
                    color: $c_font;
                    font-size: 1.125rem /* 18/16 */;
                    font-weight: bold;
                    &:hover{
                        color: $c_main;
                    }
                }
            }
        }
        .des{
            @include lineLimit(1);
            color: $c_fontL;
            font-size: 1rem /* 16/16 */;
            margin-top: 5px;
        }
    }
    .more-btn{
        background: $c_main;
        border-radius: 3px;
        color: $c_white;
        display: block;
        font-size: .9375rem /* 15/16 */;
        padding: 13px 10px;
        text-align: center;
        width: 130px;
        &:hover{
            background: $c_active;
            color: $c_font;
        }
    }
    .link-list{
        width: 30%;
    }
    .link{
        border: 1px solid #d1c0a5;
        background: $c_white;
        border-radius: 3px;
        padding: 5px;
        &:not(:last-child){
            margin-bottom: 30px;
        }
        a{
            display: block;
            position: relative;
        }
        .link-title{
            @include abs_pos($l:0, $b:0);
            background: rgba($c_white, .8);
            color: $c_font;
            font-size: 1rem /* 16/16 */;
            font-weight: bold;
            padding: 13px 10px;
            text-align: center;
            width: 100%;
        }
    }
}

.index-gallery {
    background: url(../images/index-gallery-bg.jpg) no-repeat;
    background-size: cover;
    .index-head{
        justify-content: center;
        transform: translateY(-80%);
        margin-bottom: -20px;
    }
    .gallery-grid{
        padding-top: 70px;
        padding-bottom: 70px;
        margin: 0 -20px;
        a{
            position: relative;
            &:hover .gallery-title{
                opacity: 1;
            }
        }
        .gallery-item{
            padding: 0 20px;
            position: relative;
        }
        .gallery-title{
            @include abs_pos($t:0, $l:0);
            @include flex($ai:center, $jfc:center);
            @include size(100%);
            background: rgba(black, .6);
            opacity: 0;
            transition: all .4s ease;
            padding: 15px;
            p{
                color: $c_white;
                font-size: 1.0625rem /* 17/16 */;
                font-weight: bold;
                line-height: 1.2;
                text-align: center;
                width: 100%;
            }
        }
    }
    .slick-dots{
        display: flex;
        justify-content: center;
        gap: 15px;
        margin-top: 15px;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        .slick-active button{
            background: $c_active;
        }
        button{
            @include size(12px);
            border-radius: 50%;
            background: #aaa;
            font-size: 0;
        }
    }
}