/* -------------- guru RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-guru{
        grid-template-columns: 1fr;
    }
}

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }