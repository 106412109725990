/* -------------- header --------------*/
.header{
    background: url(../images/header-bg.jpg), $c_main;
    background-repeat: no-repeat;
    background-position: 15% center;
    padding: 15px 0;
    .frame{
        max-width: 1680px;
    }
    .logo{
        @include maw(385px);
        display: block;
        img{
            display: block;
            width: 100%;
        }
    }
}


.mobile-toggle {
    @include size(28px, 30px);
    background: none;
    display: none;
    transform: translateY(13px);
    padding: 0;
    z-index: 4;

    &.open {

        span,
        span:before,
        span:after {
            background: $c_white;
        }

        span {
            background: none;

            &:before {
                transform: rotate(45deg) translate(6px, 5px);
            }

            &:after {
                transform: rotate(-45deg) translate(7px, -8px);
            }
        }
    }

    span {
        display: block;
        position: relative;

        &:before,
        &:after {
            @include abs_pos($t: -9px, $l: 0);
            content: "";
        }

        &:after {
            top: 9px;
        }
    }

    span,
    span:before,
    span:after {
        @include size(100%, 4px);
        backface-visibility: hidden;
        border-radius: 2px;
        background: $c_font;
        transition: all .4s ease;
    }
}