/* -------------- layout --------------*/
* {
	box-sizing: border-box;
	outline: none;
	word-break: break-word;
}

html, body{
	@include maw(1920px);
	padding: 0;
	margin: 0;
	margin-left: auto;
	margin-right: auto;
}

body{
	font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
	-webkit-text-size-adjust: 100%;
}

.hide{
	display: none !important;
}

.clearfix{
	@extend %clearfix;
}
a{
	text-decoration: none;
	transition: all .4s ease;
}
button{
	transition: all .4s ease;
}

.unreset{
	line-height: initial;
	a{
		text-decoration: underline;
	}
	img{
		max-width: 100% !important;
		height: auto !important; 
	}
	.oembed-provider-youtube {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe, object, embed{
			@include size(100%);
			@include abs_pos($t:0, $l:0);
		}
	}
	p{
		color: $c_font;
		font-size: 1rem /* 16/16 */;
		line-height: 1.6;
	}
}

nav{
	background: url(../images/menu-bg.jpg) no-repeat;
	padding: 20px;
	.main-menu{
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 0;
		padding-left: 0;
		gap: 180px;
		.active a{
			color: $c_active;
		}
		a{
			color: $c_white;
			font-size: 1.0625rem /* 17/16 */;
			font-weight: bold;
			line-height: 1.2;
			&:hover{
				color: $c_active;
			}
		}
	}
}

.img-limit{
	position: relative;
	&.person-img{
		@include fiximg(100%, calc(630 / 530 * 100%));
	}
	&.temple-img{
		@include fiximg(100%, calc(630 / 1350 * 100%));
	}
	&.link-img{
		@include fiximg(100%, calc(405 / 600 * 100%));
	}
	&.gallery-img{
		@include fiximg(100%, calc(385 / 600 * 100%));
	}
	&.news-img{
		@include fiximg(100%, calc(240 / 345 * 100%));
	}
	&.guru-img{
		@include fiximg(100%, calc(695 / 540 * 100%));
	}
}

.page-banner{
	img{
		display: block;
		width: 100%;
	}
}

.page-main-row{
	background: url(../images/rp-page.jpg);
}

.breadcrumb{
	@include flex($jfc:flex-end);
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 0;
	list-style: none;
	margin: 0;
	li{
		margin: 2px 0;
		&:not(:last-child){
			&:after{
				content: '/';
				color: $c_light;
				font-size: 1rem /* 16/16 */;
				padding: 0 5px;
			}
		}
	}
	a{
		color: $c_light;
		font-size: 1rem /* 16/16 */;
		line-height: 1.2;
		&:hover{
			color: $c_main;
		}
		svg{
			font-size: .875rem /* 14/16 */;
			margin-right: 3px;
		}
	}
}

.page-main{
	@include flex($ai:flex-start, $jfc:space-between);
	padding-top: 60px;
	padding-bottom: 50px;
}
aside{
	width: 330px;
	.aside-head{
		position: relative;
		img{
			display: block;
			width: 100%;
		}
		p{
			@include abs_pos($l:25px, $t:50%);
			color: $c_white;
			font-size: 1.875rem /* 30/16 */;
			font-weight: bold;
			margin: 0;
			transform: translateY(-50%);
			&:before{
				@include abs_pos($b:-10px, $l:0);
				@include size(45px, 2px);
				background: rgba($c_white, .5);
				content: '';
			}
		}
	}
	.aside-menu{
		margin: 0;
		padding-left: 0;
		list-style: none;
		li{
			border-bottom: 1px solid #ddd;
			padding: 2px 0;
			&.active{
				a{
					background: #d9ccb8;
					padding-left: 40px;
					position: relative;
					&:before{
						@include abs_pos($t:15px, $l:25px);
						content: '\f0da';
						color: $c_dark;
						font-family: 'Font Awesome 6 Free';
						font-weight: bold;
					}
				}
			}
		}
		a{
			color: $c_dark;
			display: block;
			font-size: 1.125rem /* 18/16 */;
			line-height: 1.2;
			padding: 17px 25px;
			&:hover{
				color: $c_main;
				text-decoration: underline;
			}
		}
	}
}
.page-main-content{
	width: calc(100% - 330px - 40px);
	&.wide{
		width: 100%;
	}
}

.page-detail-head{
	p{
		margin: 0;
	}
	.main-text{
		border-bottom: 1px solid #d9d9d9;
		color: $c_font;
		font-size: 1.875rem /* 30/16 */;
		font-weight: bold;
		line-height: 1.4;
		padding-bottom: 15px;
	}
	.bottom-row{
		@include flex($ai:flex-start, $jfc:space-between);
		border-top: 2px solid $c_white;
		padding-top: 15px;
	}
}

.sns-share{
	.a2a_kit{
		display: flex;
		a{
			margin-left: 10px;
			&:hover{
				opacity: .7;
			}
		}
		svg{
			font-size: 1.125rem /* 18/16 */;
			line-height: 1;
		}
		.a2a_button_facebook{
			color: #406bc3;
		}
		.a2a_button_line{
			color: #07b53b;
		}
		.a2a_button_x{
			color: #000;
		}
		.copyToClipboard{
			color: $c_light;
		}
	}
}

.detail-content{
	border-bottom: 1px solid #d9d9d9;
	padding: 60px 40px;
}
.detail-bottom-row{
	border-top: 2px solid $c_white;
	padding-top: 50px;
	.back-btn{
		background: $c_light;
		border-radius: 3px;
		color: $c_white;
		display: block;
		font-size: 1rem /* 16/16 */;
		line-height: 1;
		padding: 15px;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		width: 150px;
		&:hover{
			background: $c_main;
		}
	}
}

.alert-temp{
	background: $c_light;
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 15px;
	width: 100%;
	display: none;
	&.show{
		display: block;
	}
	p{
		color: $c_white;
		font-size: 1rem /* 16/16 */;
		line-height: 1.2;
		text-align: center;
		margin: 0;
	}
}