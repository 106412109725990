/* -------------- gallery RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .page-gallery-list{
        gap: 30px 20px;
    }
}

@media (max-width: $w_pc_m){ 
    .page-gallery-detail{
        .main-slider{
            .slick-arrow{
                background: $c_white;
            }
            .slick-prev{
                left: 20px;
            }
            .slick-next{
                right: 20px;
            }
        }
        .nav-slider{
            margin: 40px -10px 0;
            .img-box{
                margin: 0 10px;
            }
        }
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-gallery-list{
        grid-template-columns: repeat(2, 1fr);
    }
    .page-gallery-detail{
        .main-slider{
            .slick-arrow{
                @include size(30px);
                svg{
                    font-size: .875rem /* 14/16 */;
                }   
            }
            .slick-prev{
                left: 15px;
            }
            .slick-next{
                right: 15px;
            }
        }
    }
    .page-gallery-video{
        .video-box{
            padding: 10px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .page-gallery-list{
        grid-template-columns: 1fr;
    }
    .page-gallery-detail{
        .main-slider .img-des{
            font-size: 1rem /* 16/16 */;
            padding: 10px;
        }
        .nav-slider{
            margin: 20px -7px 0;
            .img-box{
                margin: 0 7px;
            }
        }
    }
    .page-gallery-video{
        .video-box{
            padding: 5px;
        }
    }
}

// @media (max-width: $w_mo){ }