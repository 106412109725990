/* -------------- header RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .header{
        .logo{
            max-width: 300px;
        }
    }
}

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .header{
        .logo{
            max-width: 240px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .header{
        background-position: 25% center;
        padding: 10px 0;
    }
}

// @media (max-width: $w_mo){ }