/* -------------- guru --------------*/
.page-guru{
    align-items: flex-start;
    display: grid;
    grid-template-columns: 45% auto;
    gap: 20px 40px;
    .img-box{
        border: 1px solid #ddd;
        background: $c_white;
        padding: 10px;
        max-width: 540px;
    }
}