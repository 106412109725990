/* -------------- pagination --------------*/
.page_box {
    @include flex($jfc: center);
    margin-top: 60px;

    .page_num {
        @include size(36px);
        @include flex($ai: center, $jfc: center);
        border-radius: 50%;
        color: $c_fontL;
        font-size: 1rem
            /* 16/16 */
        ;
        margin-left: 5px;
        margin-right: 5px;

        &:hover {
            background: #eee;
        }

        &.active {
            background: $c_light;
            color: $c_white;
        }

        i {
            font-size: 1rem
                /* 16/16 */
            ;
        }
    }

    .page-select{
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 20px;
        select{
            border: 1px solid #eee;
            font-size: 1rem /* 16/16 */;
            padding: 8px;
            padding-right: 25px;
        }
        span{
            color: $c_font;
            font-size: .9375rem /* 15/16 */;
        }
    }
}