/* -------------- footer --------------*/
.footer{
    background: $c_main;
    padding: 45px 0;
    p{
        margin: 0;
    }
    .frame{
        @include flex($jfc:space-between, $ai:flex-end);
        gap: 20px;
        max-width: 1680px;
    }
    .footer-menu{
        @include flex;
        gap: 30px;
        list-style: none;
        margin: 0;
        padding-left: 0;
        a{
            color: $c_white;
            font-size: 1.0625rem /* 17/16 */;
            font-weight: bold;
            &:hover{
                color: $c_active;
            }
        }
    }
    .contact-info{
        @include flex;
        gap: 30px;
        margin-top: 15px;
        .info, a, span{
            color: $c_white;
            font-size: 1rem /* 16/16 */;
            line-height: 1.3;
        }
        a:hover{
            color: $c_active;
        }
        .tel-data{
            display: inline-block;
        }
    }
    .right-sec{
        text-align: right;
        .right{
            color: $c_white;
            font-size: 1rem /* 16/16 */;
            line-height: 1.3;
            margin-bottom: 10px;
        }
        .designed, b{
            font-size: .875rem /* 14/16 */;
            color: $c_active;
        }
    }
}

.btn-top{
    background: none;
    position: fixed;
    right: 10px;
    bottom: 30vh;
    padding: 0;
    padding-top: 100px;
    opacity: 0;
    width: 16px;
    &:before{
        @include abs_pos($t:0, $l:50%);
        @include size(1px, 80px);
        background: $c_font;
        content: '';
    }
    &:after{
        @include abs_pos($t:-4px, $l:calc(50% - 4px));
        @include size(8px);
        background: $c_active;
        border-radius: 50%;
        content: '';
    }
    p{
        color: $c_font;
        font-size: 1rem /* 16/16 */;
        line-height: 1;
        margin: 0;
        writing-mode: tb-rl;
    }
}