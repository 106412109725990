/* -------------- gallery --------------*/
.page-gallery{
    .info{
        align-items: center;
        display: flex;
        gap: 15px;
        .date{
            flex-shrink: 0;
        }
        p{
            margin: 0;
        }
        p, span{
            color: $c_light;
            font-size: .875rem /* 14/16 */;
            line-height: 1.2;
        }
        svg{
            color: $c_light;
            font-size: .8125rem /* 13/16 */;
            margin-right: 5px;
        }
        .line{
            @include size(100%, 1px);
            background: #ddd;
        }
    }
}

.gallery-frame{
    max-width: $w_pc;
}

.page-gallery-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 30px;
    .gallery{
        .img-box{
            position: relative;
            &:hover .mask{
                opacity: 1;
                transform: scale(1);
            }
            .mask{
                @include abs_pos($t:0, $l:0);
                @include size(100%);
                @include flex($ai:center);
                background: rgba(black, .65);
                opacity: 0;
                transform: scale(.5);
                transition: all .4s ease;
                overflow: hidden;
            }
            .content{
                color: $c_white;
                font-size: 1rem /* 16/16 */;
                line-height: 1.2;
                text-align: center;
                width: 100%;
                svg{
                    font-size: 1.25rem /* 20/16 */;
                }
                p{
                    margin-top: 15px;
                    margin-bottom: 0;
                }
            }
        }
        .content{
            margin-top: 15px;
            .gallery-title{
                margin-bottom: 15px;
                a{
                    @include lineLimit(1);
                    color: $c_font;
                    font-size: 1.125rem /* 18/16 */;
                    font-weight: bold;
                    &:hover{
                        color: $c_main;
                    }
                }
            }
        }
    }
}

.page-gallery-detail{
    .main-slider{
        position: relative;
        .img-limit{
            display: block;
        }
        .img-des{
            background: $c_light;
            color: $c_white;
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.3;
            padding: 15px;
            margin: 0;
            margin-top: 1px;
            text-align: center;
            width: 100%;
        }
        .slick-arrow{
            @include flex($ai:center, $jfc:center);
            @include abs_pos($t:50%);
            @include size(45px);
            border-radius: 50%;
            border: 1px solid $c_light;
            background: none;
            z-index: 1;
            &:hover{
                background: $c_light;
                svg{
                    color: $c_white;
                }
            }
            svg{
                color: $c_light;
                font-size: 1.125rem /* 18/16 */;
            }
        }
        .slick-prev{
            left: -30px;
            transform: translate(-100%, -50%);
        }
        .slick-next{
            right: -30px;
            transform: translate(100%, -50%);
        }
    }
    .nav-slider{
        margin: 50px -15px 0;
        .img-box{
            margin: 0 15px;
        }
    }
}
.lb-dataContainer .lb-data .lb-caption{
    font-size: 1rem /* 16/16 */;
    line-height: 1.6;
}

.page-gallery-video{
    .video-box{
        border: 1px solid #ddd;
        border-top: none;
        background: $c_white;
        padding: 15px;
    }
    .video-bottom{
        display: flex;
        gap: 20px;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .video-des{
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 20px;
    }
}