/* -------------- layout RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .page-main{
        padding-top: 40px;
    }
    aside{
        width: 240px;
        .aside-head p{
            left: 15px;
            font-size: 1.375rem /* 22/16 */;
        }
        .aside-menu {
            li.active a{
                padding-left: 32px;
                &:before{
                    font-size: .9375rem /* 15/16 */;
                    top: 11px;
                    left: 20px;
                }
            }
            a{
                font-size: 1rem /* 16/16 */;
                padding: 12px 20px;
            }
        }
    }
    .page-main-content{
        width: calc(100% - 240px - 40px);
    }
    .page-detail-head .main-text{
        font-size: 1.625rem /* 26/16 */;
    }
}

@media (max-width: $w_pc_m){ 
    .page-main{
        padding-top: 30px;
    }
    aside{
        margin-bottom: 30px;
        width: 100%;
        .aside-head{
            display: none;
        }
        .aside-menu{
            @include flex;
            li{
                border: none;
                padding: 0;
            }
            a{
                border: 1px solid #d9ccb8;
            }
        }
    }
    .page-main-content{
        width: 100%;
    }
    .detail-content{
        padding: 30px 0;
    }
}

@media (max-width: $w_tb_b){ 
    nav{
        .main-menu{
            gap: 90px;
        }
    }
    .breadcrumb{
        justify-content: flex-start;
        padding: 10px 0;
        a{
            font-size: .9375rem /* 15/16 */;
        }
    }
}

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    nav{
        padding: 15px 10px;
        .main-menu{
            gap: 20px;
        }
    }
    .page-main{
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .page-detail-head {
        .main-text{
            font-size: 1.5rem /* 24/16 */;
            padding-bottom: 10px;
        }
        .bottom-row{
            padding-top: 10px;
        }
    }
    .sns-share .a2a_kit a{
        margin-left: 5px;
    }
    .detail-bottom-row{
        padding-top: 30px;
    }
}

// @media (max-width: $w_mo){ }