/* -------------- footer RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .footer{
        padding: 35px 0;
        .contact-info{
            display: block;
            .info:not(:last-child){
                margin-bottom: 5px;
            }
        }
    }
}

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .footer{
        padding: 25px 0;
        .frame{
            display: block;
        }
        .right-sec{
            border-top: 1px solid rgba($c_white, .3);
            text-align: left;
            margin-top: 15px;
            padding-top: 15px;
        }
    }
}

@media (max-width: $w_tb){ 
    .btn-top{
        bottom: 100px;
        right: 0px;
    }
}

@media (max-width: $w_tb_m){ 
    .footer{
        .footer-menu{
            gap: 20px;
            a{
                font-size: 1rem /* 16/16 */;
            }
        }
        .contact-info{
            .info, a, span{
                font-size: .9375rem /* 15/16 */;
            }
        }
        .right-sec{
            .right{
                font-size: .875rem /* 14/16 */;
                margin-bottom: 5px;
            }
            .designed, b{
                font-size: .8125rem /* 13/16 */;
            }
        }
    }
}

// @media (max-width: $w_mo){ }