/* -------------- index RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .index-banner{
        padding: 0 10px;
    }
    .index-head{
        margin-bottom: 20px;
    }
    .index-main{
        padding-top: 60px;
        padding-bottom: 80px;
        .index-news{
            margin-bottom: 40px;
        }
        .news{
            margin-bottom: 15px;
        }
    }
    .index-gallery {
        .gallery-grid{
            padding-top: 50px;
            padding-bottom: 50px;
            margin: 0 -10px;
            .gallery-item{
                padding: 0 10px;
            }
        }
    }   
}

@media (max-width: $w_pc_m){ 
    .index-main{
        .right-sec{
            width: 100%;
        }
        .link-list{
            @include flex($jfc:space-between);
            margin-top: 30px;
            width: 100%;
        }
        .link{
            width: calc(100% / 3 - 20px);
            &:not(:last-child){
                margin-bottom: 0;
            }
        }
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .index-main{
        .link{
            width: calc(100% / 3 - 10px);
        }
    }
}

@media (max-width: $w_tb_m){ 
    .index-banner{
        padding: 0 5px;
    }
    .index-head {
        margin-bottom: 15px;
        .main{
            font-size: 1.5rem /* 24/16 */;
            &:after{
                padding: 0 10px;
            }
        }
        .sub{
            font-size: 1.5rem /* 24/16 */;
        }
    }
    .index-main{
        padding-top: 40px;
        padding-bottom: 50px;
        .news{
            .top-row{
                display: block;
                .date{
                    margin-bottom: 7px;
                }
            }
        }
        .link{
            width: 100%;
            &:not(:last-child){
                margin-bottom: 15px;
            }
        }
    }
    .index-gallery {
        padding-top: 30px;
        .index-head{
            transform: translateY(0);
            margin-bottom: 0;
        }
        .gallery-grid{
            padding-top: 25px;
            padding-bottom: 30px;
            margin: 0;
            .gallery-item{
                padding: 0;
            }
        }
    }   
}

// @media (max-width: $w_mo){ }