/* -------------- news --------------*/
.page-news{
    .infos{
        @include flex($ai:baseline);
        gap: 5px 15px;
        p, span{
            color: $c_light;
            font-size: .875rem /* 14/16 */;
            line-height: 1.2;
        }
        svg{
            color: $c_light;
            font-size: .8125rem /* 13/16 */;
            margin-right: 5px;
        }
    }
}
.page-news-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 40px;
    .news{
        border: 1px solid #e6e6e6;
        background: $c_white;
        padding: 15px;
        p{
            margin: 0;
        }
        .infos{
            margin: 10px 0;
        }
        .img-box{
            &:hover img{
                transform: scale(1.1);
            }
            .img-limit{
                overflow: hidden;
            }
            img{
                transform: scale(1);
                transition: all .4s ease
            }
        }
        .news-title{
            @include lineLimit(1);
            margin: 10px 0;
            a{
                color: $c_font;
                font-size: 1.125rem /* 18/16 */;
                font-weight: bold;
                line-height: 1.4;
                &:hover{
                    color: $c_main;
                }
            }
        }
        .des{
            border-top: 1px solid #ddd;
            padding: 15px 0;
            p{
                @include lineLimit(1);
                color: $c_fontL;
                font-size: 1rem /* 16/16 */;
                line-height: 1;
            }
        }
    }
}